<script lang="ts" setup generic="O = any, V = any">
import {
  ref,
  computed,
  watch,
  nextTick,
  shallowRef,
  type VNodeProps,
  type VNode,
  type ComponentPublicInstance,
} from 'vue';
import { unrefElement } from '@vueuse/core';
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
import { IconSearch, IconX } from '@tabler/icons-vue';
import {
  useFocusZone,
  useOptionsList,
  type OptionsListItem,
  type OptionsListGroup,
} from '../../composables';

import type { Path, OptionsListProps } from '../../shared/types';
import { Keys } from '../../shared/enums';
import { vFocus } from '../../directives';
import { areValuesEqual, getClosestFocusable } from '../../utils';
import { ObSeparator } from '../separator';
import { ObButtonIcon } from '../button-icon';
import { ObScrollableContainer } from '../scrollable-container';
import { ObSpinner } from '../spinner';
import {
  ObActionListContextProvider,
  ObActionList,
  ObActionListItem,
  ObActionListGroup,
  ObActionListGroupList,
} from '../action-list';
import { ObPrimitiveInput } from '../primitive-input';
import { ObHostedDropdown } from '../hosted-dropdown';

type Props = OptionsListProps<O, V> & {
  open?: boolean;
  title?: string;
  subtitle?: string;
  withSearch?: boolean;
  withGroups?: boolean;
  hideEmptyGroups?: boolean;
  withSelectAll?: boolean;
  externalSearch?: boolean;
  searchPlaceholder?: string;
  groups?: Array<{
    key: string;
    title?: string | null;
  }>;
  optionsLoading?: boolean;
  optionGroup?: Path<O> | ((option: O) => string);
  selectionMode?: 'single' | 'multiple';
} & (
    | { selectionMode?: 'single'; modelValue?: V | null }
    | { selectionMode?: 'multiple'; modelValue?: V[] }
  );

defineOptions({
  inheritAttrs: false,
});

const {
  title,
  subtitle,
  withSearch = false,
  withGroups = false,
  hideEmptyGroups = true,
  withSelectAll = false,
  searchPlaceholder,
  selectionMode = 'single',
  options = [],
  groups,
  externalSearch = false,
  optionsLoading = false,
  optionDisabled,
  optionLabel,
  optionValue,
  optionGroup,
  trackValueBy,
} = defineProps<Props>();

defineEmits<{
  'update:modelValue': [value: (V | null) | V[]]; // TODO: correct types base on selection mode?
  'update:search': [query: string];
}>();

defineSlots<{
  host?: (props: { open: boolean; hostProps: VNodeProps }) => VNode;
  default?: (props: {
    optionsList: OptionsListItem<O, V>[];
    optionsGroups: OptionsListGroup<O, V>[];
    selectOption: (option: OptionsListItem<O, V>) => void;
    search: string;
    selectAll: () => void;
    toggleAll: () => void;
    allOptionsSelected: boolean;
    selectedOptions: OptionsListItem<O, V>[];
  }) => VNode;
}>();

// TODO: is it possible to use `useTemplateRef` with Function Refs?
const hostRef = shallowRef<HTMLElement | null>(null);
const containerRef = shallowRef<HTMLElement | null>(null);
const optionsRef = shallowRef<HTMLElement | null>(null);
const searchInputRef = shallowRef<HTMLInputElement | null>(null);

// TODO: need v-model?
const open = ref(false);

let activeDescendant: HTMLElement | undefined;

const { focusFirst, focusLast } = useFocusZone({
  container: optionsRef,
  activeDescendantControl: searchInputRef,
  onActiveDescendantChanged: (current, previous, directlyActivated) => {
    activeDescendant = current;

    if (directlyActivated) {
      activeDescendant?.scrollIntoView({ block: 'nearest', inline: 'start' });
    }
  },
});

const { activate: activateTrap, deactivate: deactivateTrap } = useFocusTrap(containerRef, {
  escapeDeactivates: false,
  allowOutsideClick: true,
  clickOutsideDeactivates: true,
  returnFocusOnDeactivate: false,
  fallbackFocus: () => {
    return containerRef.value as HTMLElement;
  },
});

watch(
  open,
  (value) => {
    if (value) {
      nextTick(() => {
        activateTrap();
      });
      return;
    }

    deactivateTrap();
  },
  { immediate: true },
);

function onEsc(event: KeyboardEvent) {
  event.preventDefault();
  event.stopPropagation();
  open.value = false;
  nextTick(() => {
    // TODO: maybe manage with useFocusTrap config?
    hostRef.value?.focus();
  });
}

function onTab(event: KeyboardEvent) {
  event.preventDefault();
  event.stopImmediatePropagation();

  open.value = false;

  nextTick(() => {
    if (!hostRef.value) {
      return;
    }

    const target =
      getClosestFocusable({
        initial: hostRef.value,
        root: document.documentElement,
        previous: event.shiftKey,
      }) ?? hostRef.value;

    target?.focus();
  });
}

function onHostClick(event: MouseEvent) {
  event.preventDefault();
  open.value = !open.value;
}

function onHostKeydown(event: KeyboardEvent) {
  if (event.key === Keys.ArrowDown) {
    event.preventDefault();
    open.value = true;
    nextTick(() => {
      focusFirst();
    });
    return;
  }

  if (event.key === Keys.ArrowUp) {
    event.preventDefault();
    open.value = true;
    nextTick(() => {
      focusLast();
    });
    return;
  }
}

function onItemSelect() {
  if (selectionMode !== 'single') {
    return;
  }

  open.value = false;
  nextTick(() => {
    // TODO: maybe manage with useFocusTrap config?
    hostRef.value?.focus();
  });
}

function onSearchInputKeydown(event: KeyboardEvent) {
  if (event.key === Keys.Enter && activeDescendant) {
    event.preventDefault();
    event.stopImmediatePropagation();

    // Forward Enter key press to active descendant so that item gets activated
    const activeDescendantEvent = new KeyboardEvent(event.type, event);
    activeDescendant.dispatchEvent(activeDescendantEvent);
  }
}

const hostProps = computed(() => ({
  onClick: onHostClick,
  onKeydown: onHostKeydown,
  'aria-haspopup': true,
  'aria-expanded': open.value,
}));

function onClickClose(event: MouseEvent) {
  event.preventDefault();
  open.value = !open.value;
}

const modelValue = defineModel<V | null | V[]>({ default: null });
const search = defineModel<string>('search', { default: '' });

const {
  optionsList,
  filteredOptionsList,
  optionsGroups,
  optionsGroupsWithEmpty,
  allOptionsSelected,
  selectedOptions,
} = useOptionsList<O, V>({
  options: computed(() => options), // reactivity
  groups,
  optionDisabled,
  optionLabel,
  optionValue,
  optionGroup,
  trackValueBy,
  selectionMode,
  search: computed(() => {
    if (externalSearch) {
      return '';
    }

    return search.value;
  }),
  selected: modelValue,
});

const visibleOptionsGroups = computed(() =>
  hideEmptyGroups ? optionsGroups.value : optionsGroupsWithEmpty.value,
);

// TODO: make these methods part of useOptionsList()

function selectOption(option: OptionsListItem<O, V>) {
  if (selectionMode === 'single') {
    modelValue.value = option.value;
    return;
  }

  if (!Array.isArray(modelValue.value)) {
    modelValue.value = [];
  }

  if (option.selected) {
    modelValue.value = modelValue.value.filter(
      (item) => !areValuesEqual<V>(item, option.value, trackValueBy),
    );
    return;
  }

  modelValue.value = [...modelValue.value, option.value];
}

function selectAll() {
  if (selectionMode === 'single') {
    return;
  }

  modelValue.value = optionsList.value.map(({ value }) => value);
}

function toggleAll() {
  if (selectionMode === 'single') {
    return;
  }

  if (allOptionsSelected.value) {
    modelValue.value = [];
    return;
  }

  selectAll();
}
</script>

<template>
  <ObHostedDropdown
    :active="open"
    tabindex="-1"
    role="dialog"
    @keydown.esc="onEsc"
    @keydown.tab="onTab"
    @click-outside="open = false"
  >
    <template #host="{ hostProps: dropdownHostProps }">
      <slot
        name="host"
        v-bind="{
          open,
          hostProps: {
            ...dropdownHostProps,
            ...hostProps,
            ref(el: Element | ComponentPublicInstance | null) {
              dropdownHostProps.ref(el);
              hostRef = unrefElement(el as any); // TODO: how not to cast no any? Element vs HTMLElement
            },
          },
        }"
      />
    </template>
    <div ref="containerRef" :class="$style.container">
      <div :class="$style.header">
        <div :class="$style.headerTop">
          <div v-if="title" :class="$style.title">{{ title }}</div>
          <ObButtonIcon
            variant="tertiary"
            size="xs"
            aria-label="Close"
            tabindex="-1"
            @click="onClickClose"
          >
            <IconX aria-hidden="true" />
          </ObButtonIcon>
        </div>
        <div v-if="subtitle" :class="$style.subtitle">
          {{ subtitle }}
        </div>
        <div v-if="withSearch">
          <ObPrimitiveInput v-focus size="s" clearable>
            <input
              ref="searchInputRef"
              v-model="search"
              type="text"
              :placeholder="searchPlaceholder"
              aria-haspopup="listbox"
              autocomplete="off"
              @keydown="onSearchInputKeydown"
            />
            <template #icon>
              <IconSearch aria-hidden="true" />
            </template>
          </ObPrimitiveInput>
        </div>
      </div>
      <ObSeparator />
      <ObScrollableContainer light>
        <div ref="optionsRef">
          <div v-if="optionsLoading" :class="$style.noData">
            <ObSpinner size="48px" />
            Loading
          </div>
          <div v-else-if="!filteredOptionsList.length" :class="$style.noData">
            {{ search ? `No items found for '${search}'` : 'No items found' }}
          </div>
          <ObActionListContextProvider
            v-else
            list-role="listbox"
            item-role="option"
            :on-after-select="onItemSelect"
          >
            <ObActionList :selection-mode="selectionMode" compact>
              <ObActionListItem
                v-if="withSelectAll && !search"
                :selected="allOptionsSelected"
                :indeterminate="!allOptionsSelected && selectedOptions.length > 0"
                @select="toggleAll()"
              >
                <span :class="$style.selectAll">All</span>
              </ObActionListItem>
              <slot
                v-bind="{
                  optionsList: filteredOptionsList,
                  optionsGroups: visibleOptionsGroups,
                  selectOption,
                  search,
                  selectAll,
                  toggleAll,
                  allOptionsSelected,
                  selectedOptions,
                }"
              >
                <template v-if="withGroups">
                  <ObActionListGroup
                    v-for="group in optionsGroups"
                    :key="group.key"
                    :title="group.title"
                  >
                    <ObActionListGroupList>
                      <ObActionListItem
                        v-for="option in group.options"
                        :key="option.label"
                        :selected="option.selected"
                        :disabled="option.disabled"
                        @select="selectOption(option)"
                      >
                        {{ option.label }}
                      </ObActionListItem>
                    </ObActionListGroupList>
                  </ObActionListGroup>
                </template>
                <template v-else>
                  <ObActionListItem
                    v-for="option in filteredOptionsList"
                    :key="option.label"
                    :selected="option.selected"
                    :disabled="option.disabled"
                    @select="selectOption(option)"
                  >
                    {{ option.label }}
                  </ObActionListItem>
                </template>
              </slot>
            </ObActionList>
          </ObActionListContextProvider>
        </div>
      </ObScrollableContainer>
    </div>
  </ObHostedDropdown>
</template>

<style lang="scss" module>
@use '../../styles/colors';
@use '../../styles/shared';
@use '../../styles/typography';

.container {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  max-width: 480px;
  max-height: 480px;
}

.header {
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.headerTop {
  display: flex;
  align-items: center;
  justify-content: end;
}

.title {
  color: #021148;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  min-width: 0;
  padding-right: 12px;
}

.subtitle {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #9aa0b6;
}

.noData {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  gap: 4px;
  padding: 48px 8px;
}

.selectAll {
  font-weight: 600;
}
</style>
