<script lang="ts" setup>
import { computed, useSlots, useCssModule, h, type VNode } from 'vue';
import type { AnchorTargetAttribute, SizeM, SizeL } from '../../shared/types';

interface Props {
  asChild?: boolean;
  disabled?: boolean;
  size?: SizeM | SizeL;
  tabIndex?: string;
  href?: string;
  rel?: string;
  target?: AnchorTargetAttribute;
}

interface SlotScope {
  rootProps: any; // TODO: type for slot scope
}

const props = withDefaults(defineProps<Props>(), {
  asChild: false,
  disabled: false,
  size: 'm',
  tabIndex: undefined,
  href: undefined,
  rel: undefined,
  target: undefined,
});

defineSlots<{
  default?: (props: SlotScope) => VNode;
}>();

const style = useCssModule();

const rootProps = computed<SlotScope['rootProps']>(() => ({
  tabindex: props.disabled ? undefined : props.tabIndex,
  href: props.disabled ? undefined : props.href,
  rel: props.rel,
  target: props.target,
  class: [
    style.root,
    {
      [style.disabled]: props.disabled,
      [style.sizeL]: props.size === 'l',
    },
  ],
}));

const slots = useSlots();

defineRender(() => {
  const children = slots.default?.({ rootProps: rootProps.value });

  return props.asChild
    ? children
    : h(
        'a',
        {
          ...rootProps.value,
        },
        children,
      );
});
</script>

<style lang="scss" module>
@use '../../styles/colors';
@use '../../styles/shared';
@use '../../styles/typography';

.root {
  @include shared.reset-button();
  font-size: 14px; // TODO: use typography settings
  line-height: 20px;
  display: inline-flex;
  align-items: center;
  font-style: normal;
  text-transform: inherit;
  font-family: typography.$font-family-primary;
  font-weight: 400;
  letter-spacing: 0.025em;
  color: colors.$hyperlink;
  text-decoration: none;
  touch-action: manipulation;
  border-radius: shared.$border-radius-s;
  -webkit-tap-highlight-color: transparent;

  &:focus-visible {
    outline: 1px solid colors.$hyperlink;
    outline-offset: 2px;
  }

  &:hover:enabled,
  &:hover:not(.disabled) {
    text-decoration: underline;
    text-decoration-skip-ink: auto;
    text-decoration-thickness: 1px;
    text-underline-offset: 0.1em;
  }
}

.disabled {
  cursor: not-allowed;
  color: colors.$hyperlink-40;
}

.sizeL {
  font-size: 16px; // TODO: use typography settings
  line-height: 22px;
}
</style>
